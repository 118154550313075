export const OrderStatus = {
  PreOrder: "Pre order",
  Partial: "Partial shipped",
  Pending: "Preparing to ship",
  Shipped: "Shipped",
  Delivered: "Delivered",
  Canceled: "Canceled",
  refunded: "Refunded",
};

export const ADMIN_EMAIL = "lhui1@chillfitrave.com";
