//DEV
// export const GRAPHQL_URL = "http://192.168.0.110:8000/graphql"
// export const GRAPHQL_URL = "http://localhost:8000/graphql";
// export const GRAPHQL_URL_WS = "ws://localhost:8000/graphql";
export const GRAPHQL_URL = "https://devapi.chillfitrave.com/graphql";
export const GRAPHQL_URL_WS = "wss://devapi.chillfitrave.com/graphql";

//PROD
// export const GRAPHQL_URL = "https://api.chillfitrave.com/graphql";
// export const GRAPHQL_URL_WS = "wss://api.chillfitrave.com/graphql";
